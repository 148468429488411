<div class="one-column-page-container">
  <h2>Support Desk</h2>
  @if (uiState === uiStateEnumForTemplate.ShowData) {
    <h3>Filter</h3>
    <form [formGroup]="filterForm" class="margin-bottom-med">
      <div class="flex-row-wrap justify-space-between">
        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Player Name</mat-label>
          <input matInput formControlName="playerName" data-testid="support-desk-player-name-input">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Player Email</mat-label>
          <input matInput formControlName="playerEmail" data-testid="support-desk-player-email-input">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Player Id</mat-label>
          <input matInput formControlName="playerId" data-testid="support-desk-player-id-input">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Game</mat-label>
          <mat-select formControlName="gameId" data-testid="support-desk-id-game-input">
            @for(game of (games | async); track game.Id) {
              <mat-option [value]="game.Id">{{game.Name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Error Type</mat-label>
          <mat-select formControlName="errorType" data-testid="support-desk-error-type-select">
            @for (error_type of errorTypes; track error_type.value) {
              <mat-option [value]="error_type.value" data-testid="support-desk-error-type-option">{{error_type.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Assignee</mat-label>
          <input matInput formControlName="assignee" data-testid="support-desk-assignee-input">
          <mat-hint>The Id of the admin the support request is assigned to</mat-hint>
          <button matSuffix mat-icon-button data-testid="my-assigned-requests-button" (click)="fillWithActiveUserId()" matTooltip="Search for my assigned requests">
            <mat-icon>person</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input
            [rangePicker]="picker">
            <input matStartDate placeholder="From" formControlName="fromDate" data-testid="from-date-input">
            <input matEndDate placeholder="To" formControlName="toDate" data-testid="to-date-input">
          </mat-date-range-input>
          <mat-hint>MM-DD-YYYY – MM-DD-YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>

    <div class="text-end">
      <button [disabled]="!filterForm.valid" mat-raised-button type="submit" class="primary-button margin-right-small"
              (click)="onFormSubmit()" data-testid="submit-button">Filter</button>
      <button mat-stroked-button class="primary-button" (click)="onResetClick()" data-testid="reset-button">Reset</button>
    </div>

    <ag-grid-angular
      class="ag-theme-quartz margin-top-med"
      data-testid="support-desk-table"
      [tooltipShowDelay]="0"
      [pagination]="true"
      [domLayout]="'autoHeight'"
      [suppressPaginationPanel]="true"
      (rowClicked)="onRowClicked($event)"
      overlayNoRowsTemplate="<span>No Support Requests</span>"
      (gridReady)="onGridReady($event)"
      [rowData]="supportRequests$ | async"
      [columnDefs]="columnDefs">
    </ag-grid-angular>
    <div class="custom-paginator">
      <span>{{ fromNumber }}</span> to <span>{{ toNumber }}</span> of <span
      class="margin-right-large">{{ totalRecords }}</span>
      <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
      <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before</mat-icon>
      Page <span>{{ pageNumber }}</span> of <span>{{ totalPages }}</span>
      <mat-icon (click)="onBtNext()" [ngClass]="pageNumber === pagedSupportRequestResponse?.TotalPages ? 'disabled' : ''">
        navigate_next
      </mat-icon>
      <mat-icon (click)="onBtLast()" [ngClass]="pageNumber === pagedSupportRequestResponse?.TotalPages ? 'disabled' : ''">last_page
      </mat-icon>
    </div>
  }
</div>
