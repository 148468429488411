import {ColDef} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SupportDeskColDefs {

  constructor(private datePipe: DatePipe) {
  }

  private errorTypes = [{name: "Cannot Receive Email", value: 1}, {name: "Cannot Login", value: 2}, {name: "Cannot Sign Up", value: 3},
    {name: "Cannot Play Tickets", value: 4}, {name: "Cannot Update Payment Method", value: 5}, {name: "Cannot Update Charity Pledge", value: 6},
    {name: "Cannot Update Password", value: 7}, {name: "Cannot Update Email", value: 8}, {name: "Cannot Update Personal Information", value: 9},
    {name: "Other", value: 10}];

  public colDefs: ColDef[] = [{
    headerName: "Sentry Event Id",
    field: 'SentryEventId',
    resizable: true,
    sortable: true
  },
  {
    headerName: "Game Id",
    field: 'GameId',
    resizable: true,
    sortable: true
  },
  {
    headerName: "Player Id",
    field: 'PlayerId',
    resizable: true,
    sortable: true
  },
  {
    headerName: "Player Name",
    field: 'PlayerName',
    resizable: true,
    sortable: true
  },
  {
    headerName: "Player Email",
    field: 'PlayerEmail',
    resizable: true,
    sortable: true
  },
  {
    headerName: "Error Type",
    field: 'ErrorType',
    resizable: true,
    sortable: true,
    valueFormatter: params => {
      return this.errorTypes[params.value - 1].name;
    }
  },
  {
    headerName: "Description",
    field: 'ErrorDescription',
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Created On",
    field: 'CreatedOn',
    resizable: true,
    sortable: true,
    valueFormatter: params => {
      const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
      return convertedDate ? convertedDate : '';
    }
  },
  {
    headerName: "Assignee",
    field: 'Assignee',
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Resolved",
    field: 'Resolved',
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Resolved On",
    field: 'ResolvedOn',
    resizable: true,
    sortable: true,
    valueFormatter: params => {
      const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
      return convertedDate ? convertedDate : '';
    }
  },
  {
    headerName: "Resolved By",
    field: 'ResolvedBy',
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Resolution Description",
    field: 'ResolutionDescription',
    resizable: true,
    sortable: true,
  }
];

}
