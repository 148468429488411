import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {AdminAppMode} from "../enum/AdminAppMode";

@Injectable({
  providedIn: 'root'
})
export class AdminAppModeService {

  private adminAppModeSubject : BehaviorSubject<AdminAppMode> = new BehaviorSubject<AdminAppMode>(AdminAppMode.GameAdmin);
  public adminAppMode$ : Observable<AdminAppMode> = this.adminAppModeSubject.asObservable();

  public enableGameAdminMode() {
    this.adminAppModeSubject.next(AdminAppMode.GameAdmin);
  }

  public enableCausableAdminMode() {
    this.adminAppModeSubject.next(AdminAppMode.CausableAdmin);
  }

  public enableCharityAdminMode() {
    this.adminAppModeSubject.next(AdminAppMode.CharityAdmin);
  }
}
