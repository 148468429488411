import {Component} from '@angular/core';
import {map, Observable} from "rxjs";
import {ISupportRequest} from "../../interfaces/player/ISupportRequest";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DateService} from "../../services/date.service";
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatOption, MatSelect} from "@angular/material/select";
import {GameService} from "../../services/game.service";
import {AgGridAngular} from "ag-grid-angular";
import {GridApi, GridReadyEvent, RowClickedEvent} from "ag-grid-community";
import {ISupportRequestFilter} from "../../interfaces/ISupportRequestFilter";
import {SupportRequestService} from "../../services/support-request.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {ISupportRequestPagedResponse} from "../../interfaces/player/ISupportRequestPagedResponse";
import {SupportDeskColDefs} from "./support-desk-column-defs";
import {MatDialog} from "@angular/material/dialog";
import {
  SupportRequestDialogComponent
} from "../../components/dialogs/support-request-dialog/support-request-dialog.component";
import {ActiveUserService} from "../../services/active-user.service";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-support-desk',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatButton,
    MatIcon,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatStartDate,
    MatHint,
    MatSuffix,
    AsyncPipe,
    MatSelect,
    MatOption,
    AgGridAngular,
    NgClass,
    MatIconButton,
    MatTooltip
  ],
  templateUrl: './support-desk.component.html',
  styleUrl: './support-desk.component.scss'
})
export class SupportDeskComponent {
  public pagedSupportRequestResponse: ISupportRequestPagedResponse | null = null;
  public supportRequests$: Observable<ISupportRequest[]> = this.supportRequestService.supportRequests$.pipe(map((response) => {
    this.pagedSupportRequestResponse = response;
    return response.Data;
  }));
  public games = this.gameService.getGamesForUser();
  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public gridApi!: GridApi;

  public columnDefs = this.supportDeskColumnDefs.colDefs;

  public errorTypes = [{name: "Cannot Receive Email", value: 1}, {name: "Cannot Login", value: 2}, {name: "Cannot Sign Up", value: 3},
    {name: "Cannot Play Tickets", value: 4}, {name: "Cannot Update Payment Method", value: 5}, {name: "Cannot Update Charity Pledge", value: 6},
    {name: "Cannot Update Password", value: 7}, {name: "Cannot Update Email", value: 8}, {name: "Cannot Update Personal Information", value: 9},
    {name: "Other", value: 10}];

  public playerNameControl = new FormControl<string | null>("");
  public playerEmailControl = new FormControl<string | null>("");
  public playerIdControl = new FormControl<string | null>("");
  public gameIdControl = new FormControl<string | null>("");
  public errorTypeControl = new FormControl<number | null>(null);
  public assigneeControl = new FormControl<string | null>("");
  public fromDateControl = new FormControl(this.dateService.twoWeeksAgo());
  public toDateControl = new FormControl(this.dateService.tomorrow());

  public filterForm = new FormGroup({
    playerName: this.playerNameControl,
    playerEmail: this.playerEmailControl,
    playerId: this.playerIdControl,
    gameId: this.gameIdControl,
    errorType: this.errorTypeControl,
    assignee: this.assigneeControl,
    fromDate: this.fromDateControl,
    toDate: this.toDateControl
  });
  fromNumber: number = 0;
  toNumber: number = 0;
  totalRecords: number = 0;
  pageNumber: number = 0;
  totalPages: number = 0;


  constructor(private supportRequestService: SupportRequestService,
              private dateService: DateService,
              private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private matDialog: MatDialog,
              private supportDeskColumnDefs: SupportDeskColDefs,
              private activeUserService: ActiveUserService) {
  }

  ngOnInit() {
    this.getPaginatedResponse(1);
  }

  onFormSubmit() {
    this.getPaginatedResponse(1);
  }

  onResetClick() {
    this.playerNameControl.setValue("");
    this.playerEmailControl.setValue("");
    this.playerIdControl.setValue("");
    this.gameIdControl.setValue("");
    this.assigneeControl.setValue("");
    this.errorTypeControl.setValue(null);
    this.fromDateControl.setValue(this.dateService.twoWeeksAgo());
    this.toDateControl.setValue(this.dateService.tomorrow());

    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number) {
    let filter: ISupportRequestFilter = {
      PlayerName: this.playerNameControl.value!,
      PlayerEmail: this.playerEmailControl.value!,
      PlayerId: this.playerIdControl.value!,
      GameId: this.gameIdControl.value!,
      ErrorType: this.errorTypeControl.value!,
      Assignee: this.assigneeControl.value!,
      FromDate: this.fromDateControl.value!,
      ToDate: this.toDateControl.value!,
      PageNumber: pageNumberP,
      PageSize: 15
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.supportRequestService.getSupportRequests(filter).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.updatePaginator();
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onRowClicked(paramsP: RowClickedEvent<ISupportRequest>) {
    this.matDialog.open(SupportRequestDialogComponent, {
      data: paramsP.data,
      width: "650px"
    }).afterClosed().subscribe({
      next: () => {
        this.getPaginatedResponse(1);
      },
    });
  }

  onGridReady(paramsP: GridReadyEvent<ISupportRequest>) {
    this.gridApi = paramsP.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 90
    });
  }

  updatePaginator() {
    this.pageNumber = this.pagedSupportRequestResponse!.PageNumber;
    this.totalPages = this.pagedSupportRequestResponse!.TotalPages;
    this.totalRecords = this.pagedSupportRequestResponse!.TotalRecords;
    let toNumber = this.pageNumber * this.pagedSupportRequestResponse!.PageSize;
    if (toNumber > this.totalRecords) {
      this.toNumber = this.totalRecords;
      this.fromNumber = (toNumber - this.pagedSupportRequestResponse!.PageSize) + 1;
    } else {
      this.toNumber = toNumber;
      this.fromNumber = (this.toNumber - this.pagedSupportRequestResponse!.PageSize) + 1;
    }
  }

  onBtFirst() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(1);
  }

  onBtLast() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pagedSupportRequestResponse?.TotalPages!);
  }

  onBtNext() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber + 1);
  }

  onBtPrevious() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber - 1);
  }

  fillWithActiveUserId() {
    this.assigneeControl.setValue(this.activeUserService.activeUser().Id);
  }
}
