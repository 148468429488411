import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserIsAdminGuard} from "./guards/user-is-admin-guard";
import {LogoutPage} from "./pages/auth/logout/logout.page";
import {UserIsNotAdminPageComponent} from "./pages/user-is-not-admin-page/user-is-not-admin-page.component";
import {DrawWinnerPageComponent} from "./pages/draw-winner/draw-winner-page.component";
import {ManageGameComponent} from "./pages/manage-game/manage-game.component";
import {PlayerSearchComponent} from "./pages/player-search/player-search.component";
import {GameSelectedGuard} from "./guards/game-selected-guard";
import {WinnerPayoutsComponent} from "./pages/winner-payouts/winner-payouts.component";
import {ManageUsersComponent} from "./pages/manage-users/manage-users.component";
import {RecoverPasswordComponent} from "./pages/recover-password/recover-password.component";
import {UpdatePasswordComponent} from "./pages/update-password/update-password.component";
import {UpdateEmailAddressComponent} from "./pages/update-email-address/update-email-address.component";
import {GetAllGamesForUserGuard} from "./guards/get-all-games-for-user.guard";
import {AuditingComponent} from "./pages/auditing/auditing.component";
import {ReportsComponent} from "./pages/reports/reports.component";
import {UserDetailsComponent} from "./pages/user-details/user-details.component";
import {PermissionGuard} from "./guards/permission-guard";
import {PermissionTypes} from "./enum/PermissionTypes";
import {NotAuthorizedComponent} from "./pages/not-authorized/not-authorized.component";
import {ManagePermissionsComponent} from "./pages/manage-permissions/manage-permissions.component";
import {TestingPageComponent} from "./pages/testing-page/testing-page.component";
import {GameInstancesComponent} from "./pages/game-instances/game-instances.component";
import {NewGameComponent} from "./pages/new-game/new-game.component";
import {EnvironmentGuard} from "./guards/environment-guard";
import {GameRulesEditorComponent} from "./pages/game-rules-editor/game-rules-editor.component";
import {HowToPlayEditorComponent} from "./pages/how-to-play-editor/how-to-play-editor.component";
import {CharityManagementComponent} from "./pages/manage-charities/charity-management.component";
import {ActiveGameInstancesComponent} from "./pages/active-game-instances/active-game-instances.component";
import {LoginGuard} from "./guards/login.guard";
import {AdminAuthCallbackPage} from "./pages/auth/admin-auth-callback/admin-auth-callback.page";
import {LoginComponent} from "./pages/auth/login/login.component";
import {GetGamesForUserResolver} from "./resolvers/get-games-for-user.resolver";
import {CausableAdminGuard} from "./guards/causable-admin-guard";
import {CausableAdminDashboardComponent} from "./pages/causable-admin-dashboard/causable-admin-dashboard.component";
import {
  FrequentlyAskedQuestionsComponent
} from "./pages/frequently-asked-questions/frequently-asked-questions.component";
import {LandingComponent} from "./pages/landing/landing.component";
import {CharityComponent} from "./pages/charity/charity.component";
import {CharityAdminGuard} from "./guards/charity-admin.guard";
import {SupportDeskComponent} from "./pages/support-desk/support-desk.component";
import {ManagePlayerComponent} from "./pages/manage-player/manage-player.component";

const routes: Routes = [
  {
    path: '',
    title: 'Landing',
    canActivate: [UserIsAdminGuard, GetAllGamesForUserGuard],
    component: LandingComponent
  },
  {
    path: 'not-admin',
    title: 'Not Admin',
    component: UserIsNotAdminPageComponent
  },
  {
    path: 'not-authorized',
    title: 'Not Authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'forgot-my-password',
    title: 'Recover Password',
    component: RecoverPasswordComponent
  },
  {
    path: 'testing-page',
    title: 'Testing Page',
    component: TestingPageComponent,
    canActivate: [EnvironmentGuard, CausableAdminGuard]
  },
  {
    path: 'auth/admin-auth-callback',
    canActivate: [LoginGuard],
    component: AdminAuthCallbackPage
  },
  {
    path: 'auth/login',
    canActivate: [LoginGuard],
    component: LoginComponent
  },
  {
    path: 'logout',
    title: 'Logout',
    canActivate: [UserIsAdminGuard],
    component: LogoutPage
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'update-password',
    canActivate: [UserIsAdminGuard],
    title: 'Update Password',
    component: UpdatePasswordComponent
  },
  {
    path: 'update-email-address',
    title: 'Update Email Address',
    canActivate: [UserIsAdminGuard],
    component: UpdateEmailAddressComponent
  },
  {
    path: 'causable-admin-dashboard',
    title: 'Causable Admin dashboard',
    canActivate: [CausableAdminGuard],
    component: CausableAdminDashboardComponent
  },
  {
    path: 'frequently-asked-questions',
    title: 'Frequently Asked Questions',
    canActivate: [CausableAdminGuard],
    component: FrequentlyAskedQuestionsComponent
  },
  {
    path: 'game-instances',
    title: 'Game Instances',
    canActivate: [CausableAdminGuard],
    component: GameInstancesComponent
  },
  {
    path: 'new-game',
    title: 'New Game',
    resolve: {
      games: GetGamesForUserResolver
    },
    canActivate: [CausableAdminGuard],
    component: NewGameComponent,
    children: [
      {
        path: ':/newGameId',
        component: NewGameComponent
      },
    ]
  },
  {
    path: 'support-desk',
    title: 'Support Desk',
    canActivate: [CausableAdminGuard],
    component: SupportDeskComponent
  },
  {
    path: 'charity/:charityId',
    title: 'Charity',
    canActivate: [CharityAdminGuard],
    component: CharityComponent
  },

  // admin required routes
  {
    path: '',
    canActivate: [UserIsAdminGuard],
    children: [
      {
        path: 'player-search',
        title: 'System Player Search',
        data: {
          globalSearch: true
        },
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGlobalPlayerSearch)],
        component: PlayerSearchComponent
      },
      {
        path: 'manage-player/:playerId',
        title: 'Manage Game Global Player',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGlobalPlayerSearch)],
        component: ManagePlayerComponent
      },
    ]
  },
  // game and admin required routes
  {
    path: ':gameId',
    canActivate: [UserIsAdminGuard, GameSelectedGuard],
    children: [
      {
        path: 'auditing',
        title: 'Auditing',
        component: AuditingComponent
      },
      {
        path: 'winner-payouts',
        title: 'Winner Payouts',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewWinnerPayouts)],
        component: WinnerPayoutsComponent
      },
      {
        path: 'reports',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewReports)],
        title: 'Reports',
        component: ReportsComponent
      },
      {
        path: 'manage-users',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'Manage Users',
        component: ManageUsersComponent
      },
      {
        path: 'manage-permissions',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewRolesAndPermissions)],
        title: 'Manage Permissions',
        component: ManagePermissionsComponent
      },
      {
        path: 'user-details/:userId',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'User',
        component: UserDetailsComponent
      },
      {
        path: 'draw-winner',
        title: 'Draw Winner',
        canActivate: [() => PermissionGuard(PermissionTypes.DrawGameWinner)],
        component: DrawWinnerPageComponent
      },
      {
        path: 'manage-game',
        title: 'Manage Game',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGameSettings)],
        component: ManageGameComponent
      },
      {
        path: 'manage-player/:playerId',
        title: 'Manage Game Players',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewPlayerInfo)],
        component: ManagePlayerComponent
      },
      {
        path: 'manage-charities',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewCharities)],
        title: 'Charity Management',
        component: CharityManagementComponent
      },
      {
        path: 'active-game-instances',
        title: 'Current Draw',
        component: ActiveGameInstancesComponent
      },
      {
        path: 'player-search',
        title: 'Player Search',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewPlayerInfo)],
        component: PlayerSearchComponent
      },
      {
        path: 'edit-game-rules',
        title: 'Edit Game Rules',
        component: GameRulesEditorComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ModifyGameSettings)]
      },
      {
        path: 'edit-how-to-play',
        title: 'Edit How To Play',
        component: HowToPlayEditorComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ModifyGameSettings)]
      }
    ]
  }
];

// initialNavigation: "disabled" stops navigation until we are ready (re-enabled in app component when org and auth config have been set up)
@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: "disabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
