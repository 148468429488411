import {Component, Inject} from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {PlayersService} from "../../../services/players.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatButton} from "@angular/material/button";
import {ISuspendPlayer} from "../../../interfaces/player/ISuspendPlayer";
import {GameService} from "../../../services/game.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IGamePaymentSettings} from "../../../interfaces/new-game/breakdown/IGamePaymentSettings";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";

@Component({
  selector: 'app-suspend-player',
  standalone: true,
  imports: [
    DialogBaseComponent,
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatButton,
    MatLabel
  ],
  templateUrl: './suspend-player.component.html',
  styleUrl: './suspend-player.component.css'
})
export class SuspendPlayerComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public reasonTypes = [{name: "Payment Dispute", value: 1},
    {name: "Inappropriate Behavior", value: 2}, {name: "Suspicious Activity", value: 3}]

  public suspensionReasonControl = new FormControl<number | null>(null, Validators.required);

  public suspendPlayersForm = new FormGroup({
    suspensionReason: this.suspensionReasonControl
  });

  constructor(private playerService: PlayersService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<SuspendPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public gamePlayerData: IGamePlayerInputData) {
  }

  suspendPlayer() {
    if (!this.suspendPlayersForm.valid)
    {
      this.suspendPlayersForm.markAllAsTouched()
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let command: ISuspendPlayer = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.gamePlayerData.game.Id,
      PlayerId: this.gamePlayerData.playerId,
      ReasonType: this.suspensionReasonControl.value!
    }

    this.playerService.suspendPlayer(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully suspended player!");
        this.uiState = UIStateEnum.ShowData;
        this.matDialogRef.close(true);
      },
      error: err => {
        this.errorHandlingService.displayDialogLevelErrorMessage("Failed to suspend player", err);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }
}
