import {Component, inject, OnInit} from '@angular/core';
import {CharityService} from "../../services/charity.service";
import {ActivatedRoute} from "@angular/router";
import {concatMap, forkJoin, Observable, of} from "rxjs";
import {ICharityWithContributionTotals} from "../../interfaces/charity/ICharityWithContributionTotals";
import {AsyncPipe, DatePipe} from "@angular/common";
import {PipesModule} from "../../pipes/pipes.module";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ICharitySupporterTotalsReport} from "../../interfaces/charity/ICharitySupporterTotalsReport";
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule, MatEndDate, MatStartDate} from "@angular/material/datepicker";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular";
import {ICharityDateRangeReport} from "../../interfaces/charity/ICharityDateRangeReport";
import {MatButton} from "@angular/material/button";
import {ChooseGameInstanceComponent} from "../../components/choose-game-instance/choose-game-instance.component";
import {IGameInstance} from "../../interfaces/IGameInstance";
import {GameService} from "../../services/game.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {
  CharityDrawReportingComponent
} from "../../components/charity/charity-draw-reporting/charity-draw-reporting.component";

@Component({
  selector: 'app-charity',
  standalone: true,
  imports: [AsyncPipe,
    DatePipe,
    PipesModule,
    MatProgressSpinner,
    MatCardModule,
    MatDatepickerModule,
    MatEndDate,
    MatFormField,
    MatLabel,
    MatStartDate,
    MatSuffix,
    ReactiveFormsModule,
    ContainerComponent, RowComponent, ColComponent, MatButton, ChooseGameInstanceComponent, CharityDrawReportingComponent],
  templateUrl: './charity.component.html',
  styleUrl: './charity.component.css'
})
export class CharityComponent implements OnInit {
  protected charityService: CharityService = inject(CharityService);
  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected gameService: GameService = inject(GameService);
  protected datePipe: DatePipe = inject(DatePipe);
  protected errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  protected charityIdFromUrl: string | null = null;
  protected charitySupportersReport: ICharitySupporterTotalsReport | null = null;
  protected charityDateRangeReport: ICharityDateRangeReport | null = null;
  protected startDateControl = new FormControl('', Validators.required);
  protected endDateControl = new FormControl('', Validators.required);
  protected showDateRangeLoader: boolean = true;
  public charityDatesFromGroup = new FormGroup({
    startDate: this.startDateControl,
    endDate: this.endDateControl
  });

  uiState: UIStateEnum = UIStateEnum.ShowLoading;
  uiStateEnumForTemplate = UIStateEnum;

  protected activeCharity$: Observable<ICharityWithContributionTotals | undefined> = this.charityService.activeCharity$;

  ngOnInit() {
    this.initializeCharityData();
  }

  private initializeCharityData() {
    this.activatedRoute.paramMap.pipe(
      concatMap((paramMapP) => {
        this.charityIdFromUrl = paramMapP.get('charityId');
        if (this.charityIdFromUrl) {
          this.initDates();
          return this.charityApiCalls(this.charityIdFromUrl);
        }
        return of(null);
      })
    ).subscribe({
      next: (res) => {
        if (res == null) {
          this.errorHandlingService.displayPageLevelErrorMessage(`Looks like there is an issue with the Charity ${this.charityIdFromUrl} you are trying to reach.`);
        } else {
          const [gameResult, charitySupportersReport, dateRangeReport] = res;
          // charityWithContributionTotals are stored in state and handled via activeCharity$
          this.gameService.updateActiveGame(gameResult);
          this.charitySupportersReport = charitySupportersReport;
          this.charityDateRangeReport = dateRangeReport;
          this.showDateRangeLoader = false;
        }
        this.uiState = UIStateEnum.ShowData;

      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  private initDates() {
    this.startDateControl.setValue('2020-01-01');

    // this will put the end date 2 weeks from today, which will allow the most current draw to be included
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);

    this.endDateControl.setValue(this.datePipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss.SSS', 'UTC'));
  }

  private charityApiCalls(charityId: string): Observable<[IGameQueryResult, ICharitySupporterTotalsReport, ICharityDateRangeReport]> {
    return forkJoin([
      this.charityService.fetchCharitiesById(charityId).pipe(concatMap((charityRes) => this.gameService.getGameByGameId(charityRes.GameId))),
      this.charityService.fetchCharitySupporters(charityId),
      this.charityService.fetchCharityDateRangeReport(charityId, this.startDateControl.value!, this.endDateControl.value!)
    ])
  }

  protected onGenerateDateRangeReport() {
    this.showDateRangeLoader = true;
    this.charityDateRangeReport = null;
    this.charityService.fetchCharityDateRangeReport(this.charityIdFromUrl!, this.startDateControl.value!, this.endDateControl.value!).subscribe({
      next: (res) => {
        this.charityDateRangeReport = res;
        this.showDateRangeLoader = false;
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
        this.showDateRangeLoader = false;
      }
    })

  }
}
