import {Component} from '@angular/core';
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {map, Observable, of, timer} from "rxjs";
import {GameService} from "../../services/game.service";
import {AppConfigService} from "../../services/app-config.service";
import {tap} from "rxjs/operators";
import {CountdownService} from "../../services/countdown.service";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {GameInstanceService} from "../../services/game-instance.service";
import {IAppConfig} from "../../interfaces/IAppConfig";
import {PotentialEnvironments} from "../../consts/PotentialEnvironments";
import {EnvironmentType} from "../../enum/EnvironmentType";
import {MatToolbarModule} from "@angular/material/toolbar";
import {AsyncPipe} from "@angular/common";
import {UserProfileMenuComponent} from "../user-profile-menu/user-profile-menu.component";
import {PipesModule} from "../../pipes/pipes.module";
import {MatIcon} from "@angular/material/icon";
import {SideNavService} from "../../services/side-nav.service";
import {MatIconButton} from "@angular/material/button";
import {ActiveUserService} from "../../services/active-user.service";
import {SupportRequestService} from "../../services/support-request.service";
import {MatBadge} from "@angular/material/badge";
@Component({
  selector: 'app-game-banner',
  standalone: true,
  templateUrl: './game-banner.component.html',
  imports: [
    MatToolbarModule,
    AsyncPipe,
    UserProfileMenuComponent,
    PipesModule,
    MatIcon,
    MatIconButton,
    MatBadge
  ],
  styleUrls: ['./game-banner.component.scss']
})
export class GameBannerComponent {

  public activeGame$: Observable<IGameQueryResult | undefined> = this.gameService.selectActiveGame$().pipe(tap((gameP) => {
    if (gameP) {
      this.activeGameInstance$ = this.gameInstanceService.activeGameInstanceByGame(gameP.Id).pipe(tap((activeGameInstanceP) => {
        if (activeGameInstanceP) {
          this.createCountdownSubscription(new Date(activeGameInstanceP.EndedOn));
          const cdnRoot = this.appConfigService.cdnRoot();
          this.combinedLogo = this.gameService.generateAssetUrl('game-logo.svg', cdnRoot);
        }
      }))
    } else {
      this.activeGameInstance$ = of(null);
    }
  }));
  public combinedLogo: string = '';
  public currentEnvironment: string = '';
  public appConfig$: Observable<IAppConfig> = this.appConfigService.appConfig$.pipe(tap((configP) => {
    this.determineEnvironment(configP.currentEnvironment);
  }));
  public endDateCountDown$: Observable<string> = of('');
  public activeGameInstance$: Observable<IActiveGameInstance | null> = of(null);
  public assignedSupportRequestsCount$ = this.supportRequestService.assignedRequestsCount$;
  public sideNavState$ = this.sideNavService.sideNavState$;

  constructor(private gameService: GameService,
              private gameInstanceService: GameInstanceService,
              private countDownService: CountdownService,
              private sideNavService: SideNavService,
              private appConfigService: AppConfigService,
              private supportRequestService: SupportRequestService) {
  }

  private createCountdownSubscription(endDateP: Date): void {
    this.endDateCountDown$ = timer(0, 1000).pipe(map(() => {

      if (endDateP) {
        return this.countDownService.formatRemainingTimeUntilDraw(endDateP);
      }

      return '';
    }));
  }

  private determineEnvironment(currentEnvironmentStringP: string) {
    const currentEnvironment = PotentialEnvironments.find((envP) => {
      return envP.type.toLowerCase() === currentEnvironmentStringP.toLowerCase();
    });

    if (currentEnvironment && currentEnvironment.type !== EnvironmentType.Production) {
      this.currentEnvironment = currentEnvironment.name;
    }
  }

  onSideNavToggle() {
    this.sideNavService.toggleDrawer();
  }
}
