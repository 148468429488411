export enum PermissionTypes {
  ViewGameSettings = 1,
  ModifyGameSettings = 2,
  ViewReports = 3,
  ViewCharities = 4,
  ModifyCharities = 5,
  ViewPlayerInfo = 6,
  ModifyPlayerInfo = 7,
  ViewAdminUsers = 8,
  ModifyAdminUsers = 9,
  ModifyPlayerNumbers = 10,
  ViewPlayerTransactions = 11,
  ViewGameSummaryReport = 12,
  ViewPlayerNumbers = 13,
  ViewAuditingReport = 14,
  ViewSalesPerGameReport = 15,
  ViewHourlyTicketSalesReport = 16,
  UpdatePlayerActivationStatus = 17,
  ViewComprehensiveGameReport = 18,
  ViewLotteryLicenseReport = 19,
  ViewRolesAndPermissions = 20,
  ModifyRolesAndPermissions = 21,
  ModifySensitiveGameSettings = 22,
  RecalculateGameTotals = 23,
  AdminUpdatePlayerBasicInfo = 24,
  ViewAdminAudits = 25,
  ViewPlayerAudits = 26,
  ViewSystemAudits = 27,
  ViewAuditing = 28,
  DrawGameWinner = 29,
  SendEmailBlast = 30,
  CreateNewGame = 31,
  EditGameTos = 32,
  EditGameHowToPlay = 33,
  ModifyLotteryLicence = 34,
  AddPlayerNote = 35,
  UpdatePlayerNote = 36,
  ModifyTestimonials = 401,
  ModifyFrequentlyAskedQuestions = 402,
  DeactivateCharities = 403,
  ReactivateCharities = 404,
  ViewWinnerPayouts = 405,
  ModifyWinnerPayouts = 406,
  ModifyPlayerSuspensions = 502,
  ViewPlayerSuspensions = 503,
  ViewGlobalPlayerSearch = 504
}
