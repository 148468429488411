@if (playerInfo) {
  <c-container class="mt-3">
    <c-row>
      <c-col>
        <h2 data-testid="chosen-player-name">{{ playerInfo.FirstName }} {{ playerInfo.LastName }}
          <small data-testid="chosen-player-id">({{ playerInfo.Id }})</small>
        </h2>
        <div class="margin-bottom-med">
          <button mat-raised-button (click)="chooseNewPlayerClick()" data-testid="chosen-player-back-button">
            <mat-icon>arrow_back_ios</mat-icon>
            Back
          </button>
        </div>
      </c-col>
    </c-row>

    @if (allowUserToChangeGame) {
      <c-row class="mb-3">
        <c-col>
          <mat-card>
            <mat-card-content>
              <p>Choose game you would like to see player data for</p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Choose Game</mat-label>
                <mat-select [(ngModel)]="chosenGame" (ngModelChange)="onGameChange($event)" data-testid="select-game">
                  @for (game of playerInfo.PlayerGames; track game) {
                    <mat-option data-testid="game-option" [value]="game" class="choose-game-option">
                      {{ game.ShortName }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>

            </mat-card-content>
          </mat-card>
        </c-col>
        <c-col>
          @if (gameLogo) {
            <div class="p-3 text-center"
                 [style]="chosenGame?.GameThemeTypeId == GameThemeType.Dark ? 'background-color: #222222; border-radius: 4px; height: 150px' : ''">
              <img alt="game logo" style="height: 100%" [src]="gameLogo"/>
            </div>
          }
        </c-col>
      </c-row>
    }

    @if (playerIsSuspendedInCurrentGame && chosenGame) {
      <div class="suspension-banner mb-3" data-testid="player-suspended-banner">
        Looks like this player is suspended in this game. Check out the Player Suspensions section to learn more or
        investigate the suspension
      </div>
    }

    <c-row class="mb-3">
      <c-col md="3" class="mb-3">
        <div cListGroup>
          <button cListGroupItem
                  data-testid="general-info-tab"
                  (click)="currentScreenState = screenStateEnum.GeneralInfo"
                  [active]="currentScreenState === screenStateEnum.GeneralInfo">
            <div>
              <h5 class="mb-1">General Info</h5>
              <p>view general info on player</p>
            </div>
          </button>

          @if(chosenGame) {
            <button cListGroupItem
                    data-testid="player-numbers-tab"
                    *hasPermission="PermissionTypes.ViewPlayerNumbers"
                    (click)="currentScreenState = screenStateEnum.PlayerNumbersView"
                    [active]="currentScreenState === screenStateEnum.PlayerNumbersView">
              <div>
                <h5 class="mb-1">Player Number Management</h5>
                <p>view and manage this players numbers</p>
              </div>
            </button>
            <button cListGroupItem
                    data-testid="notes-tab"
                    (click)="currentScreenState = screenStateEnum.NotesView"
                    [active]="currentScreenState === screenStateEnum.NotesView">
              <div>
                <h5 class="mb-1">Player Note Management</h5>
                <p>view, edit and add players notes</p>
              </div>
            </button>
            <button cListGroupItem (click)="currentScreenState = screenStateEnum.PledgesView"
                    [active]="currentScreenState === screenStateEnum.PledgesView">
              <div>
                <h5 class="mb-1">Player Pledges</h5>
                <p>view any active and historical player charity pledges</p>
              </div>
            </button>
            <button *hasPermission="PermissionTypes.ViewPlayerTransactions"
                    cListGroupItem
                    data-testid="transactions-tab"
                    (click)="currentScreenState = screenStateEnum.TransactionView"
                    [active]="currentScreenState === screenStateEnum.TransactionView">
              <div>
                <h5 class="mb-1">Transactions / Payments</h5>
                <p>view all transactions this player has made for this game</p>
              </div>
            </button>
            <button
              *hasPermission="PermissionTypes.ViewPlayerSuspensions"
              cListGroupItem
              data-testid="suspensions-tab"
              (click)="currentScreenState = screenStateEnum.SuspensionsView"
              [active]="currentScreenState === screenStateEnum.SuspensionsView">
              <div>
                <h5 class="mb-1">Suspensions</h5>
                <p>view any active and historical suspensions for this player</p>
              </div>
            </button>
          }


        </div>

      </c-col>
      <c-col>
        @switch (currentScreenState) {
          @case (screenStateEnum.GeneralInfo) {
            <mat-card class="margin-bottom-med">
              <mat-card-header>
                <mat-card-title>General Info</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <div>
                    <div class="detail-label">Player Name</div>
                    <div class="detail-value"
                         data-testid="manage-player-name">{{ playerInfo.FirstName }} {{ playerInfo.LastName }}
                    </div>
                  </div>

                  <div>
                    <div class="detail-label">E-mail</div>
                    <div class="detail-value" data-testid="manage-player-email">{{ playerInfo.Email }}</div>
                  </div>

                  <div>
                    <div class="detail-label">Player Status</div>
                    <div class="detail-value" data-testid="manage-player-status">
                <span
                  [class]="playerInfo.Active ? 'green-text': 'red-text'">{{ playerInfo.Active ? 'Active' : 'Deactivated' }}</span>
                    </div>
                  </div>

                  <div>
                    <div class="detail-label">Phone</div>
                    <div class="detail-value" data-testid="manage-player-phone">{{ playerInfo.Phone }}</div>
                  </div>

                  <div>
                    <div class="detail-label">Player Since</div>
                    <div class="detail-value"
                         data-testid="manage-player-created-on">{{ playerInfo.CreatedOn | date: 'yyyy-MM-dd' }}
                    </div>
                  </div>

                  <div>
                    <div class="detail-label">Agreed To Terms Of Use & Privacy Policy On</div>
                    <div data-testid="manage-player-agreed-to-tou-privacy-policy-on"
                         class="detail-value">{{ playerInfo.AgreedToTermsOfUsePrivacyPolicyOn ? (playerInfo.AgreedToTermsOfUsePrivacyPolicyOn | date: 'yyyy-MM-dd') : 'Has not agreed' }}
                    </div>
                  </div>
                  <div>
                    <div class="flex-col mb-3">
                      <button class="green-text margin-top-med"
                              *hasPermission="permissionTypes.AdminUpdatePlayerBasicInfo"
                              (click)="onUpdateBasicInfoClick()"
                              data-testid="update-player-basic-info-button"
                              mat-stroked-button>
                        <mat-icon>refresh</mat-icon>
                        Update Player Basic Info
                      </button>

                      @if (playerInfo.Active) {
                        <button class="red-text margin-top-med"
                                (click)="onDeactivatePlayerClick()"
                                data-testid="deactivate-player-button"
                                mat-stroked-button>
                          <mat-icon>do_disturb</mat-icon>
                          Deactivate player
                        </button>
                      } @else {
                        <button class="green-text margin-top-med"
                                (click)="onReactivatePlayerClick()"
                                data-testid="reactivate-player-button"
                                mat-stroked-button>
                          <mat-icon>refresh</mat-icon>
                          Reactivate player
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }

          @case (screenStateEnum.PlayerNumbersView) {
            <app-manage-player-numbers
              [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-numbers>
          }

          @case (screenStateEnum.NotesView) {
            <app-manage-player-notes
              [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-notes>
          }

          @case (screenStateEnum.PledgesView) {
            <app-manage-player-pledges
              [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-pledges>
          }

          @case (screenStateEnum.TransactionView) {
            <app-manage-player-transaction
              [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-transaction>
          }

          @case (screenStateEnum.SuspensionsView) {
            <app-manage-player-suspensions [suspensions]="playerSuspensions"
                                           [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-suspensions>
          }
        }
      </c-col>
    </c-row>

  </c-container>

}


